document.addEventListener('DOMContentLoaded', function () {


    function quantity() {
        $('.btn--plus').on('click', function () {
            let inputVal = $(this).parent().find('input').val();
            $(this).parent().find('input').val(++inputVal);
            if (inputVal > 1) {
                $(this).parent().find('input').addClass('active');
            }
        });

        $('.btn--minus').on('click', function () {
            let inputVal = $(this).parent().find('input').val();
            if (inputVal == 1) {
                $(this).parent().find('input').val(1);
                $(this).parent().find('input').removeClass('active');
            } else {
                $(this).parent().find('input').val(--inputVal);
            }

            if (inputVal == 1) {
                $(this).parent().find('input').removeClass('active');
            }
        });
    }

    quantity();


    $('.tab').on('click', function (event) {
        event.preventDefault();

        $($(this).siblings()).removeClass('tab-active');
        $($(this).closest('.tabs-wrapper').siblings().find('div')).removeClass('tabs-content-active');

        $(this).addClass('tab-active');
        $($(this).attr('href')).addClass('tabs-content-active');
    });

    const swiper2 = new Swiper(".card-page-swiper-mini", {
        slidesPerView: 4,
        direction: "vertical",
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });


    const swiper = new Swiper(".card-page-swiper", {
        slidesPerView: 1,
        direction: "vertical",
        spaceBetween: 10,
        speed: 600,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper2,
        },
        breakpoints: {
            920: {
                direction: "vertical",
            },
            300: {
                direction: "horizontal",
            },
        },
    });


});